import React, { useState, useEffect } from "react"

import { Modal, ModalBody } from "reactstrap"
import {
  AddButton,
  ModalHeader,
  ModalNote,
  CautionModal,
  SuccessModal,
  VideoModal,
} from "components"
import { CurrentlySharedSolutions } from "./"
import { ShareSolutionMenu } from "./"
import { GRADES, EMPTY_ASSIGNMENT } from "../constants"

import { AccountTypeContext } from "config/accountTypeContext"

import { INSTRUCTION_SHARE_CLASSES_PLAYER_URL } from "constants/videos"

const handleShareError = error => {
  if (error) {
    switch (error.graphQLErrors[0].message) {
      case "missing-teacher-email":
        return "Some teachers are missing emails"

      case "teacher-not-found":
        return "Some teachers do not exist in system"

      case "teacher-not-in-school":
        return "You are not allowed to share a solution to some teachers"

      case "characteristics-needs-metrics":
        return "Characteristics needs metrics"

      case "requests-needs-metrics":
        return "Requests needs metrics"

      default:
        return "Error trying to share solution"
    }
  }
}

const handleUnshareError = error => {
  if (error) {
    switch (error.graphQLErrors[0].message) {
      default:
        return "Error trying to unshare solution"
    }
  }
}

const getSolutionAssignmentsFromTeacher = (
  teacherWithSharedSolution,
  shareType
) => {
  const teacherData = {
    value: teacherWithSharedSolution.id,
    label: `${teacherWithSharedSolution.firstName} ${teacherWithSharedSolution.lastName}`,
    email: teacherWithSharedSolution.email,
  }

  const sharedSolutionToken = teacherWithSharedSolution.sharedSolutionToken

  const gradesData = sharedSolutionToken.grades.map(grade => {
    return {
      value: grade.id,
      label: grade.label,
      defaultTeachers: [teacherData],
    }
  })

  const classesData = sharedSolutionToken.newClasses.map(newClass => {
    return {
      value: newClass.id,
      label: newClass.label,
      defaultTeachers: [teacherData],
    }
  })

  return [
    {
      gradesOrClasses: shareType === GRADES ? gradesData : classesData,
      teachers: [teacherData],
    },
  ]
}

const DEFAULT_SHARE_OPTIONS = {
  showTeachers: false,
  showTeacherRequests: false,
  showStudentRequests: false,
  showFriendships: false,
  showCharacteristics: false,
  showMetrics: true,
  writable: false,
}

export const ShareSolutionModalContent = ({
  teachers,
  teachersWithSharedSolutions,
  shareSolution,
  unshareSolution,
  toggle,
  schoolId,
  user,
  availableGrades,
  availableClasses,
  shareError,
  unshareError,
  requestNewToken,
  requestNewTokenError,
  allowWritableSharedSolutions,
}) => {
  // Resend caution modal
  const [resendRequested, setResendRequested] = useState(false)
  const [teacherToResendEmail, setTeacherToResendEmail] = useState()

  // Successful Share modal
  const [shareSuccessful, setShareSuccessful] = useState(false)

  // Show video modal
  const [showVideo, setShowVideo] = useState(false)

  // Some error handling state
  const shareErrorMessage = handleShareError(shareError)
  const unshareErrorMessage = handleUnshareError(unshareError)

  // Show share solution menu
  const [showShareSolutionMenu, setShowShareSolutionMenu] = useState(false)

  const [teacherWithSharedSolutionToEdit, setTeacherWithSharedSolutionToEdit] =
    useState()
  const [shareMode, setShareMode] = useState(1)
  const [shareType, setShareType] = useState(GRADES)

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  const initialMessage = `Hello Teachers

Please click on the link above to view the ${gettextObj.gettext(
    "class"
  )} lists.  Please let me know if you have any feedback.
Thanks!`

  const [message, setMessage] = useState(initialMessage)

  const [shareOptions, setShareOptions] = useState(DEFAULT_SHARE_OPTIONS)

  const sharedSolutionToken = teacherWithSharedSolutionToEdit
    ? teacherWithSharedSolutionToEdit.sharedSolutionToken
    : null

  useEffect(() => {
    const selectedShareOptions = sharedSolutionToken
      ? {
          showTeachers: sharedSolutionToken.showTeachers,
          showTeacherRequests: sharedSolutionToken.showTeacherRequests,
          showStudentRequests: sharedSolutionToken.showStudentRequests,
          showFriendships: sharedSolutionToken.showFriendships,
          showCharacteristics: sharedSolutionToken.showCharacteristics,
          showMetrics: sharedSolutionToken.showMetrics,
          writable: sharedSolutionToken.writable,
        }
      : DEFAULT_SHARE_OPTIONS

    setShareOptions(selectedShareOptions)
  }, [setShareOptions, sharedSolutionToken])

  const [assignmentsToSelect, setAssignmentsToSelect] = useState([])

  const defaultAssignments = teacherWithSharedSolutionToEdit
    ? getSolutionAssignmentsFromTeacher(
        teacherWithSharedSolutionToEdit,
        shareType
      )
    : [EMPTY_ASSIGNMENT]

  useEffect(() => {
    const assignmentsToUsePop = teacherWithSharedSolutionToEdit
      ? getSolutionAssignmentsFromTeacher(
          teacherWithSharedSolutionToEdit,
          shareType
        )
      : []

    if (
      teacherWithSharedSolutionToEdit &&
      !(shareMode === 2 || shareMode === 4)
    ) {
      setAssignmentsToSelect(assignmentsToUsePop)
    }
  }, [teacherWithSharedSolutionToEdit, shareType, shareMode])

  const extendedSetShareOptions = options => {
    setShareOptions(prevOptions => {
      if (
        allowWritableSharedSolutions &&
        options.writable &&
        !prevOptions.writable
      ) {
        // Set all options to true by default if we switch on writable
        return {
          showTeachers: true,
          showTeacherRequests: true,
          showStudentRequests: true,
          showFriendships: true,
          showCharacteristics: true,
          showMetrics: true,
          writable: true,
        }
      } else {
        return options
      }
    })
  }

  const closeEditShare = () => {
    setShowShareSolutionMenu(false)
    setTeacherWithSharedSolutionToEdit(null)
  }

  const getAllClassIdsFromGradeIds = gradeIds => {
    const classIds = availableGrades
      .filter(({ id }) => gradeIds.includes(id))
      .flatMap(({ newClasses }) => newClasses.map(({ id }) => id))

    return [...new Set(classIds)]
  }

  const onShareClick = shareType => {
    return () => {
      // Transform the assignment list of classTeachersAssignment
      //
      // {
      //   classId: ID,
      //   teacherIds: [ID],
      //   shareOptions: ShareOptions
      // }
      const classTeacherMap = assignmentsToSelect.reduce(
        (acc, { gradesOrClasses, teachers }) => {
          const teacherIds = teachers.map(({ value }) => value)
          const gradeOrClassIds = gradesOrClasses.map(({ value }) => value)
          const classIds =
            shareType === GRADES
              ? getAllClassIdsFromGradeIds(gradeOrClassIds)
              : gradeOrClassIds

          for (const classId of classIds) {
            acc[classId] = (acc[classId] || []).concat(teacherIds)
          }
          return acc
        },
        {}
      )

      const classTeachersAssignments = Object.entries(classTeacherMap).map(
        ([classId, teacherIds]) => {
          const uniqueTeacherIds = [...new Set(teacherIds)]
          return {
            classId,
            teacherIds: uniqueTeacherIds,
            shareOptions,
          }
        }
      )

      shareSolution({
        variables: {
          classTeachersAssignments: classTeachersAssignments,
          message: message,
          ccList: [user.email],
        },
      }).then(data => {
        if (data) {
          setShowShareSolutionMenu(false)
          setShareSuccessful(true)
          setTeacherWithSharedSolutionToEdit(null)
          setShareMode(1)
          setShareType(GRADES)
          setShareOptions(DEFAULT_SHARE_OPTIONS)
        }
      })
    }
  }

  const onUnshareClick = teacher => {
    unshareSolution({
      variables: {
        schoolId: schoolId,
        teachers: [teacher.id],
      },
    })
  }

  const onAddNewShareShareClick = () => {
    setTeacherWithSharedSolutionToEdit(null)
    setAssignmentsToSelect([])
    setShowShareSolutionMenu(true)
  }

  const onEditShareClick = teacher => {
    setTeacherWithSharedSolutionToEdit(teacher)
    setShowShareSolutionMenu(true)
  }

  const resendSolution = teacher => {
    const currentToken = teacher.sharedSolutionToken.token

    requestNewToken({
      variables: {
        currentToken,
        tokenType: "SHARED_SOLUTION",
        ccList: [user.email],
      },
    })

    setResendRequested(false)
  }

  const onResendClick = teacher => {
    setTeacherToResendEmail(teacher)
    setResendRequested(true)
  }

  const onUnshareAllClick = () => {
    const allTeacherIds = teachersWithSharedSolutions.map(({ id }) => id)
    unshareSolution({
      variables: {
        schoolId: schoolId,
        teachers: allTeacherIds,
      },
    })
  }

  const toggleVideo = () => {
    setShowVideo(!showVideo)
  }

  return (
    <div>
      <Modal isOpen className="shared-solution-modal modal-dialog-centered">
        <ModalHeader
          title={`Share ${gettextObj.gettext("class")} lists with teachers`}
          toggle={toggle}
          toggle2={toggleVideo}
          className="px-5 py-4"
        />
        <ModalNote text="This function grants teachers access to the Solver page ONLY. They will be able to see Student Notes, but cannot see Admin Only Requests." />
        <ModalBody className="px-5 py-4">
          {!teacherWithSharedSolutionToEdit && (
            <CurrentlySharedSolutions
              sharedTeachers={teachersWithSharedSolutions}
              onUnshareClick={onUnshareClick}
              onResendClick={onResendClick}
              onUnshareAllClick={onUnshareAllClick}
              availableGrades={availableGrades}
              allowWritableSharedSolutions={allowWritableSharedSolutions}
              editClick={onEditShareClick}
            />
          )}
          {showShareSolutionMenu ? (
            <>
              <div className="u-separator-border-top pb-4" />
              <ShareSolutionMenu
                message={message}
                setMessage={setMessage}
                shareOptions={shareOptions}
                setShareOptions={extendedSetShareOptions}
                onShareClick={onShareClick}
                shareErrorMessage={shareErrorMessage}
                unshareErrorMessage={unshareErrorMessage}
                toggle={
                  teacherWithSharedSolutionToEdit ? closeEditShare : toggle
                }
                assignmentsToSelect={assignmentsToSelect}
                setAssignmentsToSelect={setAssignmentsToSelect}
                defaultAssignments={defaultAssignments}
                shareType={shareType}
                setShareType={setShareType}
                shareMode={shareMode}
                setShareMode={setShareMode}
                availableClasses={availableClasses}
                availableGrades={availableGrades}
                availableTeachers={teachers}
                allowWritableSharedSolutions={allowWritableSharedSolutions}
                teacherWithSharedSolutionToEdit={
                  teacherWithSharedSolutionToEdit
                }
              />
            </>
          ) : (
            <AddButton
              text="Add New Share"
              onClick={() => onAddNewShareShareClick()}
            />
          )}
          {resendRequested && (
            <CautionModal
              isOpen
              toggle={() => setResendRequested(false)}
              heading="Resend?"
              text="Are you sure you want to resend shared lists for this teacher?"
              buttonText="Yes, send"
              onButtonClick={() => {
                resendSolution(teacherToResendEmail)
              }}
              error={requestNewTokenError}
            />
          )}
          {shareSuccessful && (
            <SuccessModal
              isOpen
              toggle={() => setShareSuccessful(false)}
              heading="List shared with teacher/s"
            />
          )}
          {showVideo && (
            <VideoModal
              toggle={toggleVideo}
              videoUrl={INSTRUCTION_SHARE_CLASSES_PLAYER_URL}
              // title="Instruction Step 1"
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}
