import { gql } from "@apollo/client"

const restrictedSolverReturnFragment = gql`
  fragment RestrictedSolverReturn on Solution {
    id
    lockedStudentIds
    activeGrade {
      id
      label
      order
    }
    classes {
      classDetails {
        label
        id
        maxClassSize
        maxSizePerGrade {
          grade {
            id
            label
            code
          }
          max
        }
        schoolGrades {
          id
          code
          order
          label
        }
        entryCriteriaStudentAttribute {
          attribute
          value
        }
        entryCriteriaCharacteristicResponse {
          characteristicResponse {
            id
            characteristic {
              id
            }
          }
        }
        entryCriteriaNullResponse {
          characteristic {
            id
          }
        }
      }
      teachers {
        id
        firstName
        lastName
      }
      students {
        id
        firstName
        lastName
        gender
        studentCode
        newGrade {
          id
        }
        currentClass {
          id
          label
        }
        characteristicResponses {
          id
          label
          description
          value
          colour
          characteristic {
            id
            name
          }
        }
      }
      counts {
        total
        genders {
          activeMaleCount
          totalMaleCount
          activeFemaleCount
          totalFemaleCount
          activeNonBinaryCount
          totalNonBinaryCount
        }
      }
    }
    metrics {
      characteristicScores {
        aggregate {
          count
          total
        }
        characteristicMetrics {
          characteristic {
            id
            name
            characteristicResponses {
              id
              colour
              label
            }
          }
          metric {
            count
            total
          }
        }
      }
      atLeastOneFriend {
        count
        total
      }
      differentTeacher {
        count
        total
      }
      genderBalance {
        count
        total
      }
      importantRequests {
        count
        total
      }
      mandatoryRequests {
        count
        total
      }
      classEntryCriteria {
        count
        total
      }
    }
    studentMetrics {
      metrics {
        mandatoryRequests {
          count
          total
        }
        importantRequests {
          count
          total
        }
        differentTeacher
        classEntryCriteria
        friendshipPreferences {
          count
          total
        }
      }
      requestsMet {
        mandatoryRequests
        importantRequests
        differentTeacher
        classEntryCriteria
        friendshipPreferences
      }
      student {
        id
      }
    }
    assignment {
      id
      movedStudents {
        student {
          id
        }
      }
      changedClasses {
        id
        label
      }
    }
  }
`

const solverReturnFragment = gql`
  fragment SolverReturn on Solution {
    id
    lockedStudentIds
    metrics(adminOnly: $adminOnly) {
      characteristicScores {
        aggregate {
          count
          total
        }
        characteristicMetrics {
          characteristic {
            id
            name
            characteristicResponses {
              id
              colour
              label
            }
          }
          metric {
            count
            total
          }
        }
      }
      atLeastOneFriend {
        count
        total
      }
      differentTeacher {
        count
        total
      }
      genderBalance {
        count
        total
      }
      importantRequests {
        count
        total
      }
      mandatoryRequests {
        count
        total
      }
      classEntryCriteria {
        count
        total
      }
    }
    studentMetrics(adminOnly: $adminOnly) {
      metrics {
        mandatoryRequests {
          count
          total
        }
        importantRequests {
          count
          total
        }
        differentTeacher
        classEntryCriteria
        friendshipPreferences {
          count
          total
        }
      }
      requestsMet {
        mandatoryRequests
        importantRequests
        differentTeacher
        classEntryCriteria
        friendshipPreferences
      }
      student {
        id
      }
    }
    activeGrade {
      id
      label
      order
    }
    classes {
      classDetails {
        label
        id
        maxClassSize
        maxSizePerGrade {
          grade {
            id
            label
            code
          }
          max
        }
        entryCriteriaStudentAttribute {
          attribute
          value
        }
        entryCriteriaCharacteristicResponse {
          characteristicResponse {
            id
            characteristic {
              id
            }
          }
        }
        entryCriteriaNullResponse {
          characteristic {
            id
          }
        }
        schoolGrades {
          id
          code
          order
          label
        }
      }
      teachers {
        id
        firstName
        lastName
      }
      students {
        id
        firstName
        lastName
        gender
        comments
        studentCode
        newGrade {
          id
          code
          order
        }
        currentClass {
          id
          label
        }
        studentConstraints(adminOnly: $adminOnly) {
          id
          constraintId
          description
          mandatory
          pair
          met
          studentTo {
            id
            firstName
            lastName
          }
          studentFrom {
            id
            firstName
            lastName
          }
        }
        teacherConstraints(adminOnly: $adminOnly) {
          id
          constraintId
          teacherToId
          teacherTo {
            id
            firstName
            lastName
          }
          mandatory
          pair
          met
        }
        characteristicResponses {
          id
          label
          description
          value
          colour
          characteristic {
            id
            name
          }
        }
        friends {
          id
          constraintId
          studentFrom {
            id
            firstName
            lastName
          }
          studentTo {
            id
            firstName
            lastName
          }
          met
        }
      }
      counts {
        total
        genders {
          activeMaleCount
          totalMaleCount
          activeFemaleCount
          totalFemaleCount
          activeNonBinaryCount
          totalNonBinaryCount
        }
      }
    }
    suggestions {
      text
      type
    }
    warnings(adminOnly: $adminOnly) {
      classesWithoutTeachers
    }
    assignment {
      id
      movedStudents {
        student {
          id
        }
      }
      changedClasses {
        id
        label
      }
    }
  }
`

export const getAssignmentsQuery = gql`
  query ($gradeId: ID!) {
    assignmentsForGradeId(gradeId: $gradeId) {
      id
      assignmentType
      user {
        firstName
        lastName
        role
      }
      movedStudents {
        student {
          id
          firstName
          lastName
        }
        to {
          id
          label
        }
        from {
          id
          label
        }
      }
      changedClasses {
        id
        label
      }
      parent {
        id
        insertedAt
      }
      insertedAt
    }
  }
`

export const getFavouritedAssignmentsQuery = gql`
  query ($gradeId: ID!) {
    favouritedAssignmentsForGradeId(gradeId: $gradeId) {
      id
      assignmentId
      notes

      user {
        firstName
        lastName
        role
      }
      insertedAt
    }
  }
`

// PREVIEW FEATURE: This is part of a beta feature which allows the user to preview an assignment without actually updating the solution
export const assignmentWithClassesMutation = gql`
  mutation ($assignmentId: ID!, $activeGradeId: ID!, $adminOnly: Boolean) {
    assignmentWithClasses(
      assignmentId: $assignmentId
      activeGradeId: $activeGradeId
    ) {
      classes {
        classDetails {
          label
          id
          maxClassSize
          maxSizePerGrade {
            grade {
              id
              label
              code
            }
            max
          }
          entryCriteriaStudentAttribute {
            attribute
            value
          }
          entryCriteriaCharacteristicResponse {
            characteristicResponse {
              id
              characteristic {
                id
              }
            }
          }
          entryCriteriaNullResponse {
            characteristic {
              id
            }
          }
          schoolGrades {
            id
            code
            order
            label
          }
        }
        teachers {
          id
          firstName
          lastName
        }
        students {
          id
          firstName
          lastName
          gender
          comments
          studentCode
          newGrade {
            id
            code
            order
          }
          currentClass {
            id
            label
          }
          studentConstraints(adminOnly: $adminOnly) {
            id
            constraintId
            description
            mandatory
            pair
            met
            studentTo {
              id
              firstName
              lastName
            }
            studentFrom {
              id
              firstName
              lastName
            }
          }
          teacherConstraints(adminOnly: $adminOnly) {
            id
            constraintId
            teacherToId
            teacherTo {
              id
              firstName
              lastName
            }
            mandatory
            pair
            met
          }
          characteristicResponses {
            id
            label
            description
            value
            colour
            characteristic {
              id
              name
            }
          }
          friends {
            id
            constraintId
            studentFrom {
              id
              firstName
              lastName
            }
            studentTo {
              id
              firstName
              lastName
            }
            met
          }
        }
        counts {
          total
          genders {
            activeMaleCount
            totalMaleCount
            activeFemaleCount
            totalFemaleCount
            activeNonBinaryCount
            totalNonBinaryCount
          }
        }
      }
    }
  }
`

const boostReturnFragment = gql`
  fragment BoostReturn on BoostSummary {
    solution {
      ...SolverReturn
    }
    new {
      solutionMetrics {
        atLeastOneFriend {
          count
          total
        }
        characteristicScores {
          aggregate {
            count
            total
          }
          characteristicMetrics {
            characteristic {
              id
              characteristicResponses {
                id
                colour
                label
              }
            }
            metric {
              count
              total
            }
          }
        }
        differentTeacher {
          count
          total
        }
        genderBalance {
          count
          total
        }
        importantRequests {
          count
          total
        }
        mandatoryRequests {
          count
          total
        }
        classEntryCriteria {
          count
          total
        }
      }
      student1 {
        class {
          id
          label
        }
        studentMetric {
          student {
            id
          }
          metrics {
            mandatoryRequests {
              count
              total
            }
            importantRequests {
              count
              total
            }
            differentTeacher
            classEntryCriteria
            friendshipPreferences {
              count
              total
            }
          }
        }
      }
      student2 {
        class {
          id
          label
        }
        studentMetric {
          student {
            id
          }
          metrics {
            mandatoryRequests {
              count
              total
            }
            importantRequests {
              count
              total
            }
            differentTeacher
            classEntryCriteria
            friendshipPreferences {
              count
              total
            }
          }
        }
      }
    }
    old {
      solutionMetrics {
        atLeastOneFriend {
          count
          total
        }
        characteristicScores {
          aggregate {
            count
            total
          }
          characteristicMetrics {
            characteristic {
              id
              characteristicResponses {
                id
                colour
                label
              }
            }
            metric {
              count
              total
            }
          }
        }
        differentTeacher {
          count
          total
        }
        genderBalance {
          count
          total
        }
        importantRequests {
          count
          total
        }
        mandatoryRequests {
          count
          total
        }
      }
      student1 {
        class {
          id
          label
        }
        studentMetric {
          student {
            id
          }
          metrics {
            mandatoryRequests {
              count
              total
            }
            importantRequests {
              count
              total
            }
            differentTeacher
            classEntryCriteria
            friendshipPreferences {
              count
              total
            }
          }
        }
      }
      student2 {
        class {
          id
          label
        }
        studentMetric {
          student {
            id
          }
          metrics {
            mandatoryRequests {
              count
              total
            }
            importantRequests {
              count
              total
            }
            differentTeacher
            classEntryCriteria
            friendshipPreferences {
              count
              total
            }
          }
        }
      }
    }
  }
  ${solverReturnFragment}
`

export const solverQuery = gql`
  query ($solution: SolutionParams!, $adminOnly: Boolean) {
    solution(solution: $solution) {
      ... on Solution {
        ...SolverReturn
      }

      ... on SolutionStatus {
        id
        state
      }
    }
  }
  ${solverReturnFragment}
`

export const restrictedSolverQuery = gql`
  query getSharedSolution($solution: SolutionParams!) {
    sharedSolution(solution: $solution) {
      ...RestrictedSolverReturn
    }
  }
  ${restrictedSolverReturnFragment}
`

export const solverStudentMetricsQuery = gql`
  query ($solution: SolutionParams!, $adminOnly: Boolean) {
    solution(solution: $solution) {
      ... on Solution {
        studentMetrics(adminOnly: $adminOnly) {
          metrics {
            mandatoryRequests {
              count
              total
            }
            importantRequests {
              count
              total
            }
            differentTeacher
            classEntryCriteria
            friendshipPreferences {
              count
              total
            }
          }
          requestsMet {
            mandatoryRequests
            importantRequests
            differentTeacher
            classEntryCriteria
            friendshipPreferences
          }
          student {
            id
          }
        }
      }

      ... on SolutionStatus {
        id
        state
      }
    }
  }
`

export const solveActiveGradeMutation = gql`
  mutation ($solution: SolveParams!, $adminOnly: Boolean) {
    runSolveActiveGrade(solveActiveGradeParams: $solution) {
      ... on Solution {
        ...SolverReturn
      }
      ... on SolutionStatus {
        id
        state
      }
    }
  }
  ${solverReturnFragment}
`

export const favouriteAnAssignmentMutation = gql`
  mutation favouriteAnAssignment($favouriteParams: FavouriteParams!) {
    favouriteAnAssignment(favouriteParams: $favouriteParams) {
      schoolId
    }
  }
`

export const unfavouriteAnAssignmentMutation = gql`
  mutation ($assignmentId: ID!) {
    unfavouriteAnAssignment(assignmentId: $assignmentId) {
      schoolId
    }
  }
`

export const updateFavouritedAssignmentMutation = gql`
  mutation updateFavouritedAssignment(
    $updateFavouritedAssignmentParams: UpdateFavouritedAssignmentParams!
  ) {
    updateFavouritedAssignment(
      updateFavouritedAssignmentParams: $updateFavouritedAssignmentParams
    ) {
      id
    }
  }
`

export const pickAnAssignmentMutation = gql`
  mutation pickAnAssignment($pickAssignmentParams: PickAssignmentParams!) {
    pickAnAssignment(pickAssignmentParams: $pickAssignmentParams) {
      schoolId
      assignment {
        id
      }
    }
  }
`

export const solverStudentQuery = gql`
  query ($id: ID!, $adminOnly: Boolean) {
    student(id: $id) {
      id
      firstName
      lastName
      gender
      studentCode
      comments
      currentClass {
        grades {
          id
          code
        }
        id
        label
      }
      currentGrade {
        id
        label
      }
      characteristicResponses {
        id
        characteristicId
        value
        colour
        label
        characteristic {
          id
          name
          priority
        }
      }
      constraintsCount(adminOnly: $adminOnly)
      friends {
        id
        studentTo {
          id
          firstName
          lastName
        }
      }
      studentConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        studentTo {
          id
          firstName
          lastName
        }
        studentFrom {
          id
          firstName
          lastName
        }
      }
      teacherConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        teacherTo {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const boostCharacteristicMutation = gql`
  mutation boostCharacteristic(
    $boostCharacteristicParams: BoostCharacteristicParams!
    $adminOnly: Boolean!
  ) {
    boostCharacteristicMetric(
      boostCharacteristicParams: $boostCharacteristicParams
      adminOnly: $adminOnly
    ) {
      ...BoostReturn
    }
  }
  ${boostReturnFragment}
`

export const boostStudentMetricMutation = gql`
  mutation ($boostStudentParams: BoostStudentParams!, $adminOnly: Boolean!) {
    boostStudentMetric(
      boostStudentParams: $boostStudentParams
      adminOnly: $adminOnly
    ) {
      ...BoostReturn
    }
  }
  ${boostReturnFragment}
`

export const boostSolutionMetricMutation = gql`
  mutation ($boostSolutionParams: BoostSolutionParams!, $adminOnly: Boolean!) {
    boostSolutionMetric(
      boostSolutionParams: $boostSolutionParams
      adminOnly: $adminOnly
    ) {
      ...BoostReturn
    }
  }
  ${boostReturnFragment}
`

export const allGradesWithNewClassesQuery = gql`
  query ($schoolId: ID!) {
    allGradesWithNewClasses(schoolId: $schoolId) {
      id
      label
      newClasses {
        id
        label
        teachers {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export const updateSelectedStudent = gql`
  mutation updateSelectedStudent(
    $id: String!
    $firstName: String!
    $lastName: String!
    $friends: [friend]
    $teacherConstraints: [teacherConstraint]
    $studentConstraints: [studentConstraints]
  ) {
    updateSelectedStudent(
      studentId: $id
      firstName: $firstName
      lastName: $lastName
      friends: $friends
      teacherConstraints: $teacherConstraints
      studentConstraints: $studentConstraints
    ) @client {
      studentId
      firstName
      lastName
      friends
      teacherConstraints
      studentConstraints
    }
  }
`

export const lockStudentMutation = gql`
  mutation lockStudent($lockParams: LockParams!) {
    lockStudent(lockParams: $lockParams) {
      studentId
    }
  }
`

export const lockStudentsMutation = gql`
  mutation lockStudents(
    $studentIds: [ID!]!
    $lockedState: Boolean!
    $solutionId: ID!
  ) {
    lockStudents(
      studentIds: $studentIds
      lockedState: $lockedState
      solutionId: $solutionId
    ) {
      studentIds
      solutionId
    }
  }
`

export const moveStudentMutation = gql`
  mutation moveStudent(
    $moveStudentParamsList: MoveStudentParamsList!
    $adminOnly: Boolean
  ) {
    moveStudent(moveStudentParamsList: $moveStudentParamsList) {
      solution {
        ...SolverReturn
      }
    }
  }
  ${solverReturnFragment}
`

export const restrictedMoveStudentMutation = gql`
  mutation moveStudent($moveStudentParamsList: MoveStudentParamsList!) {
    moveStudent(moveStudentParamsList: $moveStudentParamsList) {
      solution {
        ...RestrictedSolverReturn
      }
    }
  }
  ${restrictedSolverReturnFragment}
`

export const solverClientQuery = gql`
  query {
    solver @client {
      activeGrade
      undoMoves
      redoMoves
      lastAssignmentCached
      selectedTeacherId
      selectedStudent {
        studentId
        firstName
        lastName
        friends
        teacherConstraints
        studentConstraints
      }
    }
  }
`

export const updateSolverUndoRedo = gql`
  mutation updateSolverUndoRedo(
    $type: String!
    $assignmentObject: AssignmentObject
  ) {
    updateSolverUndoRedo(type: $type, assignmentObject: $assignmentObject)
      @client {
      assignmentObject
    }
  }
`

export const updateLastAssignmentMutation = gql`
  mutation updateLastAssignment($lastAssignmentCached: Int!) {
    updateLastAssignment(lastAssignmentCached: $lastAssignmentCached) @client {
      lastAssignmentCached
    }
  }
`

export const updateSolverActiveGrade = gql`
  mutation updateSolverActiveGrade($activeGrade: Int!) {
    updateSolverActiveGrade(activeGrade: $activeGrade) @client {
      activeGrade
    }
  }
`

export const updateSelectedTeacherId = gql`
  mutation updateSelectedTeacherId($selectedTeacherId: Int!) {
    updateSelectedTeacherId(selectedTeacherId: $selectedTeacherId) @client {
      selectedTeacherId
    }
  }
`

export const teachersWithSharedSolutions = gql`
  query ($schoolId: ID!) {
    teachersWithSharedSolutions(schoolId: $schoolId) {
      id
      firstName
      lastName
      email
      sharedSolutionToken {
        token
        showTeachers
        showTeacherRequests
        showStudentRequests
        showCharacteristics
        showMetrics
        showFriendships
        writable
        newClasses {
          id
          label
        }
        grades {
          id
          label
        }
        expired
        updatedAt
      }
    }
  }
`

export const shareSolutionMutation = gql`
  mutation (
    $schoolId: ID!
    $teachers: [ID!]!
    $schoolGrades: [ID!]!
    $showTeachers: Boolean
    $showStudentRequests: Boolean
    $showTeacherRequests: Boolean
    $showMetrics: Boolean
    $showFriendships: Boolean
    $showCharacteristics: Boolean
    $writable: Boolean
    $message: String!
    $ccList: [String!]
  ) {
    shareSolutionWithTeachers(
      schoolId: $schoolId
      teachers: $teachers
      schoolGrades: $schoolGrades
      showTeachers: $showTeachers
      showFriendships: $showFriendships
      showStudentRequests: $showStudentRequests
      showTeacherRequests: $showTeacherRequests
      showCharacteristics: $showCharacteristics
      showMetrics: $showMetrics
      writable: $writable
      message: $message
      ccList: $ccList
    ) {
      id
    }
  }
`
export const shareSolutionClassesWithTeachersMutation = gql`
  mutation (
    $classTeachersAssignments: [ClassTeachersAssignment!]!
    $message: String!
    $ccList: [String!]
  ) {
    shareSolutionClassesWithTeachers(
      classTeachersAssignments: $classTeachersAssignments
      message: $message
      ccList: $ccList
    ) {
      id
    }
  }
`

export const unshareSolutionMutation = gql`
  mutation ($schoolId: ID!, $teachers: [ID!]!) {
    unshareSolutionWithTeachers(schoolId: $schoolId, teachers: $teachers) {
      id
    }
  }
`
