import React, { useState } from "react"
import Select from "react-select"
import { useQuery, useMutation } from "@apollo/client"
import { SettingItem } from "domains/accountSettings/components"
import {
  ADMINS,
  ADMIN_ONLY_STUDENT_REQUESTS,
  ADMIN_ONLY_TEACHER_REQUESTS,
  CURRENT_SOLUTIONS,
  FRIENDSHIPS,
  NEW_CLASSES,
  STUDENTS,
  STUDENT_CHARACTERISTIC_RESPONSES,
  STUDENT_REQUESTS,
  TEACHERS,
  TEACHER_REQUESTS,
  NEW_CLASSES_AS_TAG_ONLY_CHARACTERISTICS,
} from "domains/accountSettings/constants"
import { Label, Input, Modal, Button, ModalBody } from "reactstrap"
import {
  ModalHeader,
  InlineError,
  Loader,
  defaultSelectStyles,
} from "components"
import {
  cloneSchoolMutation,
  schoolListQuery,
} from "domains/accountSettings/graphql"

import { activeSchoolGradesQuery } from "domains/classes/graphql"
import { getSchoolId } from "util/app"

export const CloneSchool = ({ school }) => {
  const dataTypes = {
    [NEW_CLASSES]: "New Classes",
    [STUDENTS]: "Students",
    [TEACHERS]: "Teachers",
    [TEACHER_REQUESTS]: "Teacher Requests",
    [ADMIN_ONLY_TEACHER_REQUESTS]: "Teacher Requests - Admin Only",
    [STUDENT_REQUESTS]: "Student Requests",
    [ADMIN_ONLY_STUDENT_REQUESTS]: "Student Requests - Admin Only",
    [FRIENDSHIPS]: "Student Friendships",
    [STUDENT_CHARACTERISTIC_RESPONSES]: "Student Responses",
    [CURRENT_SOLUTIONS]: "Solutions",
    [ADMINS]: "Admins",
    [NEW_CLASSES_AS_TAG_ONLY_CHARACTERISTICS]:
      "New Classes as Tag-Only Characteristics",
  }

  const { data, loading: loadingGrades } = useQuery(activeSchoolGradesQuery, {
    variables: { schoolId: getSchoolId() },
  })

  const availableGrades = loadingGrades
    ? []
    : data.activeSchoolGrades.map(grade => {
        return {
          label: grade.label,
          value: grade.id,
        }
      })

  const selectAll = e => {
    e.stopPropagation()

    setDataToClone({
      ...dataToClone,
      [NEW_CLASSES]: true,
      [STUDENTS]: true,
      [TEACHERS]: true,
      [TEACHER_REQUESTS]: true,
      [ADMIN_ONLY_TEACHER_REQUESTS]: true,
      [STUDENT_REQUESTS]: true,
      [ADMIN_ONLY_STUDENT_REQUESTS]: true,
      [FRIENDSHIPS]: true,
      [STUDENT_CHARACTERISTIC_RESPONSES]: true,
      [CURRENT_SOLUTIONS]: true,
      [ADMINS]: true,
    })
  }

  const defaultDataValues = Object.fromEntries(
    Object.keys(dataTypes).map(dataType => {
      if (dataType === ADMINS) {
        return [dataType, true]
      } else {
        return [dataType, false]
      }
    })
  )

  const [showModal, setShowModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [dataToClone, setDataToClone] = useState(defaultDataValues)
  const [cloneSchool, { loading }] = useMutation(cloneSchoolMutation, {
    onError: error => {
      const errorMessage = error.graphQLErrors[0].details
        ? error.graphQLErrors[0].details
        : error
      setErrorMessage(`Error - Unable to clone school - ${errorMessage}`)
    },
    onCompleted: ({ cloneSchool }) => {
      if (cloneSchool) {
        setSuccessMessage(
          `Successfully cloned to ${cloneSchool.id} - ${cloneSchool.name}`
        )
      }
    },
  })
  const toggleModal = () => setShowModal(!showModal)

  const [newSchoolName, setNewSchoolName] = useState(`${school.name} COPY`)

  const [selectedGrades, setSelectedGrades] = useState([])

  const [accountType, setAccountType] = useState("CLASS")

  const onCloneSchoolClick = () => {
    setErrorMessage(null)
    setSuccessMessage(null)
    setShowModal(false)
    const dataToCloneStrings = Object.entries(dataToClone)
      .filter(([_k, v]) => v === true)
      .map(([k, _v]) => k)

    cloneSchool({
      variables: {
        schoolId: school.id,
        cloneSchoolParams: {
          name: newSchoolName,
        },
        dataToClone: dataToCloneStrings,
        accountType: accountType,
        currentGradeIds: selectedGrades.map(option => option.value),
      },
      refetchQueries: [{ query: schoolListQuery }],
    })
  }

  const onCheckboxChange = e => {
    setDataToClone({
      ...dataToClone,
      [e.target.name]: !dataToClone[e.target.name],
    })
  }

  const onRadioChange = e => {
    setAccountType(e.target.value)
  }

  const onNewSchoolNameChange = e => {
    setNewSchoolName(`${e.target.value}`)
  }

  return (
    <>
      {loading ? (
        <Loader className="loader-container-relative" />
      ) : (
        <SettingItem onButtonClick={toggleModal} buttonText="Clone">
          Clone this school
        </SettingItem>
      )}
      {successMessage && <span className="text-success">{successMessage}</span>}
      {errorMessage && <InlineError className="mx-3" text={errorMessage} />}
      {showModal && (
        <Modal isOpen className="modal-dialog-centered u-modal__w-40rem">
          <ModalHeader
            className="p-4"
            title="Cloning this school"
            toggle={toggleModal}
          />
          <ModalBody className="px-4 d-flex flex-column align-items-center">
            <div>Are you sure you want to clone this school?</div>
            <div className="my-2 d-flex flex-column align-items-center">
              Name of new school:
              <Label key="newSchoolName" className="m-3">
                <Input
                  type="text"
                  id={newSchoolName}
                  name={newSchoolName}
                  value={newSchoolName}
                  onChange={onNewSchoolNameChange}
                />
              </Label>
            </div>

            <div>
              <Input
                type="radio"
                id={"class"}
                value={"CLASS"}
                name={"accountType"}
                onChange={onRadioChange}
              />
              <div>Classes – students move from Current Grade to New Grade</div>

              <Input
                type="radio"
                id={"camp"}
                value={"CAMP"}
                name={"accountType"}
                onChange={onRadioChange}
              />
              <div>Camp Cabins - automatically separate the boys and girls</div>

              <Input
                type="radio"
                id={"activityGroup"}
                value={"ACTIVITY_GROUP"}
                name={"accountType"}
                onChange={onRadioChange}
              />
              <div>Activity Groups – students stay in their Current Grade </div>
            </div>

            <div className="px-4 pt-4">
              <Label>
                Select the Current Grade/s for the students you would like to
                copy:
              </Label>
              <Select
                name="current_grades"
                className="c-input__multiselect"
                placeholder="Select Current Grades"
                options={availableGrades}
                value={selectedGrades}
                onChange={grades => {
                  setSelectedGrades(grades)
                }}
                closeMenuOnSelect={false}
                isMulti
                styles={defaultSelectStyles}
              />
            </div>

            <div className="my-2">Data to copy over:</div>
            <span className="cursor-pointer color-blue-mid" onClick={selectAll}>
              Select All
            </span>
            <div className="d-flex flex-wrap justify-content-center">
              {Object.entries(dataTypes).map(([dataType, description]) => (
                <Label key={dataType} className="m-3">
                  <Input
                    type="checkbox"
                    id={dataType}
                    name={dataType}
                    checked={dataToClone[dataType]}
                    onChange={onCheckboxChange}
                  />
                  {description}
                </Label>
              ))}
            </div>
            <Button
              color="primary"
              className="mt-5 w-50"
              onClick={onCloneSchoolClick}>
              Clone
            </Button>
            <span
              className="text-primary p-3 pl-5 pr-5 mt-2 cursor-pointer"
              onClick={toggleModal}>
              Cancel
            </span>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
