import React from "react"
import { Label } from "reactstrap"
import { useTable } from "react-table"
import { ClassesList } from "components"

export const CurrentlySharedSolutions = ({
  sharedTeachers,
  onUnshareClick,
  onUnshareAllClick,
  onResendClick,
  editClick,
  allowWritableSharedSolutions,
}) => {
  const renderFullName = ({ row }) => {
    const teacher = row.original
    return `${teacher.firstName} ${teacher.lastName}`
  }

  const renderSharedClasses = React.useCallback(({ row }) => {
    const classes = row.original.sharedSolutionToken.newClasses
    const rowId = row.id

    return <ClassesList classes={classes} rowId={rowId} />
  }, [])

  const renderViews = ({ row }) => {
    const optionIsSet = row.original.sharedSolutionToken

    const viewsObject = {
      "Assigned Teachers": optionIsSet.showTeachers,
      "Teacher Requests": optionIsSet.showTeacherRequests,
      "Student Requests": optionIsSet.showStudentRequests,
      "Friendship Nominations": optionIsSet.showFriendships,
      Characteristics: optionIsSet.showCharacteristics,
    }

    const text = Object.keys(viewsObject)
      .map(view => {
        return viewsObject[view] ? view : ""
      })
      .filter(str => str !== "")
      .join(", ")

    return text
  }

  const renderAbilities = ({ row }) => {
    const writable = row.original.sharedSolutionToken.writable
    const abilitiesObject = {
      "Move Students": writable,
    }

    const text = Object.keys(abilitiesObject)
      .map(view => {
        return abilitiesObject[view] ? view : ""
      })
      .filter(str => str !== "")
      .join(", ")

    return text === "" ? "View Only" : text
  }

  const renderAction = React.useCallback(
    ({ row }) => {
      const teacher = row.original
      return (
        <div className="d-flex">
          <div className="text-center pr-3">
            <span
              className="cursor-pointer color-blue-mid u-bold"
              onClick={() => editClick(teacher)}>
              Edit
            </span>
          </div>
          <div className="text-center pr-3">
            <span
              className="cursor-pointer color-blue-mid u-bold"
              onClick={() => onResendClick(teacher)}>
              Resend
            </span>
          </div>
          <div className="text-center">
            <span
              className="cursor-pointer color-blue-mid u-bold"
              onClick={() => onUnshareClick(teacher)}>
              Unshare
            </span>
          </div>
        </div>
      )
    },
    [editClick, onResendClick, onUnshareClick]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "CURRENTLY SHARED WITH",
        accessor: "fullName",
        Cell: renderFullName,
      },
      {
        Header: "CLASSES SHARED",
        accessor: "classes",
        Cell: renderSharedClasses,
      },
      {
        Header: "ALLOW TEACHERS TO VIEW",
        accessor: "views",
        Cell: renderViews,
      },
      {
        Header: "ALLOW TEACHERS TO",
        accessor: "abilities",
        Cell: renderAbilities,
      },
      {
        Header: () => (
          <div style={{ display: "flex" }}>
            <Label className="py-3 c-teachers-invite-modal__text m-0">
              ACTIONS
            </Label>
            <div style={{ position: "absolute" }}>
              <div
                className="cursor-pointer color-blue-mid u-bold text-center shared-solution-table__unshare"
                onClick={onUnshareAllClick}>
                Unshare All
              </div>
            </div>
          </div>
        ),
        accessor: "action",
        Cell: renderAction,
      },
    ],
    [renderAction, onUnshareAllClick, renderSharedClasses]
  )

  const data = React.useMemo(() => sharedTeachers, [sharedTeachers])

  const tableProps = {
    data: data,
    columns: columns,
    initialState: {
      hiddenColumns: allowWritableSharedSolutions ? [] : ["writable"],
    },
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(tableProps)

  const getStylesForColumn = (columns, index, isHeader) => {
    const column = columns[index]
    const accessor = column.accessor

    if (accessor === "fullName") {
      return "shared-solution-table__fullName not-last-column"
    } else if (accessor === "classes") {
      return "shared-solution-table__classes shared-solution-table__not-initial-column shared-solution-table__not-last-column"
    } else if (accessor === "views") {
      return "shared-solution-table__views shared-solution-table__not-initial-column shared-solution-table__not-last-column"
    } else if (accessor === "abilities") {
      return `${isHeader ? "" : "shared-solution-table__abilities-row"} shared-solution-table__not-initial-column shared-solution-table__not-last-column`
    } else if (accessor === "action") {
      return "shared-solution-table__actions shared-solution-table__not-initial-column"
    } else {
      return ""
    }
  }

  return (
    <div className="pb-4">
      <div>
        {sharedTeachers.length === 0 ? (
          "Not currently shared with any teachers "
        ) : (
          <table className="shared-solution-table" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      className={`c-teachers-invite-modal__text ${getStylesForColumn(columns, index, true)}`}
                      {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className={`py-3 c-teachers-invite-modal__text ${getStylesForColumn(columns, index, false)}`}
                          {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
