import React from "react"
import { Label, Input, FormGroup, ButtonGroup, Button } from "reactstrap"
import { ButtonBar, InlineError } from "components"
import { isSystemGenerated } from "util/teacherUtil"
import { ShareSolutionByGradesOrClasses } from "./shareSolutionByGradesOrClasses"
import { EMPTY_ASSIGNMENT, GRADES, CLASSES } from "../constants"
import { areEqualObjects } from "util/objUtil"
import { AccountTypeContext } from "config/accountTypeContext"

const SHARING_OPTION_LABELS = {
  showTeachers: "View Assigned Teachers",
  showTeacherRequests: "View Teacher Requests",
  showStudentRequests: "View Student Requests",
  showFriendships: "View Friendships",
  showCharacteristics: "View Characteristics",
  writable: "Move Students",
}

export const ShareSolutionMenu = ({
  message,
  setMessage,
  shareOptions,
  setShareOptions,
  onShareClick,
  shareErrorMessage,
  unshareErrorMessage,
  toggle,
  assignmentsToSelect,
  setAssignmentsToSelect,
  shareType,
  setShareType,
  shareMode,
  setShareMode,
  defaultAssignments,
  availableClasses,
  availableGrades,
  availableTeachers,
  allowWritableSharedSolutions,
  teacherWithSharedSolutionToEdit,
}) => {
  const selectedTeachers = assignmentsToSelect.flatMap(
    ({ teachers }) => teachers
  )
  const teachersMissingEmail = selectedTeachers.some(t =>
    isSystemGenerated(t.email)
  )

  const nonEmptyAssignments = assignmentsToSelect.filter(
    assignment => !areEqualObjects(EMPTY_ASSIGNMENT, assignment)
  )

  const hasInvalidAssignments =
    nonEmptyAssignments.length === 0 ||
    nonEmptyAssignments.some(
      ({ gradesOrClasses, teachers }) =>
        gradesOrClasses.length === 0 || teachers.length === 0
    )

  const shareDisabled = hasInvalidAssignments || teachersMissingEmail

  const transformTeacherForSelect = teacher => ({
    value: teacher.id,
    label: `${teacher.firstName} ${teacher.lastName}`,
    email: teacher.email,
  })

  const transformGradeForSelect = grade => ({
    value: grade.id,
    label: grade.label,
    defaultTeachers: grade.defaultTeachers,
  })

  const transformClassForSelect = ({ id, label, defaultTeachers }) => ({
    value: id,
    label: label,
    defaultTeachers: defaultTeachers,
  })

  const onAssignmentsChange = newAssignments => {
    if (newAssignments.length > assignmentsToSelect.length) {
      // Rows have been added, assume they are the last ones
      const lastRows = newAssignments.slice(assignmentsToSelect.length)

      setAssignmentsToSelect(
        assignmentsToSelect.concat(
          lastRows.map(newAssignment =>
            possiblyAddDefaultTeachers(EMPTY_ASSIGNMENT, newAssignment)
          )
        )
      )
    } else if (newAssignments.length < assignmentsToSelect.length) {
      // Nothing to do if row has been deleted
      setAssignmentsToSelect(newAssignments)
    } else {
      // Row has been modified
      setAssignmentsToSelect(
        newAssignments.map((newAssignment, index) => {
          return possiblyAddDefaultTeachers(
            assignmentsToSelect[index],
            newAssignment
          )
        })
      )
    }
  }

  const addUniqueTeachers = (existingTeachers, newTeachers) => {
    const allTeachers = existingTeachers.concat(newTeachers)
    return allTeachers.reduce((acc, teacher) => {
      if (acc.some(t => t.value === teacher.value)) {
        return acc
      } else {
        return [...acc, teacher]
      }
    }, [])
  }

  const possiblyAddDefaultTeachers = (oldAssignment, newAssignment) => {
    if (teacherWithSharedSolutionToEdit) {
      return newAssignment
    }

    const newGrades = newAssignment.gradesOrClasses.filter(newGrade => {
      return !oldAssignment.gradesOrClasses.some(
        oldGrade => oldGrade.value === newGrade.value
      )
    })

    const defaultTeachers = newGrades.flatMap(({ defaultTeachers }) =>
      (defaultTeachers || []).map(transformTeacherForSelect)
    )

    const teachers = addUniqueTeachers(
      newAssignment.teachers || [],
      defaultTeachers
    )
    return { ...newAssignment, teachers }
  }

  const teacherData = teacherWithSharedSolutionToEdit
    ? [
        {
          value: teacherWithSharedSolutionToEdit.id,
          label: `${teacherWithSharedSolutionToEdit.firstName} ${teacherWithSharedSolutionToEdit.lastName}`,
          email: teacherWithSharedSolutionToEdit.email,
        },
      ]
    : []

  const populateAllGrades = () => {
    const assignments = availableGrades.map(grade => {
      return possiblyAddDefaultTeachers(EMPTY_ASSIGNMENT, {
        gradesOrClasses: [transformGradeForSelect(grade)],
        teachers: teacherData,
      })
    })

    setAssignmentsToSelect(assignments)
  }

  const populateAllClasses = () => {
    const assignments = availableClasses.map(cls => {
      return possiblyAddDefaultTeachers(EMPTY_ASSIGNMENT, {
        gradesOrClasses: [transformClassForSelect(cls)],
        teachers: teacherData,
      })
    })

    setAssignmentsToSelect(assignments)
  }

  const isWritableSharedSolution =
    allowWritableSharedSolutions && shareOptions.writable

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  const legendText = teacherWithSharedSolutionToEdit
    ? `ALLOW ${teacherWithSharedSolutionToEdit.firstName.toUpperCase()} ${teacherWithSharedSolutionToEdit.lastName.toUpperCase()} TO`
    : "ALLOW TEACHERS TO"

  return (
    <>
      <FormGroup>
        <Label className="c-teachers-invite-modal__text pb-2">MESSAGE</Label>
        <Input
          type="textarea"
          className="c-teachers-invite-modal__message"
          name="message"
          value={message}
          rows={5}
          onChange={e => {
            setMessage(e.target.value)
          }}
        />
      </FormGroup>
      <FormGroup>
        <legend className="c-teachers-invite-modal__text pb-2">
          {legendText}
        </legend>
        <div className="d-flex">
          {Object.entries(shareOptions).map(([id, value]) => {
            // Don't display the `writable` option if school doesn't allow it
            const label =
              id === "writable" && !allowWritableSharedSolutions
                ? null
                : SHARING_OPTION_LABELS[id]

            if (label) {
              return (
                <div key={id} className="mx-4">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={value}
                      disabled={
                        !["writable", "showTeachers"].includes(id) &&
                        isWritableSharedSolution
                      }
                      onChange={() => {
                        setShareOptions({ ...shareOptions, [id]: !value })
                      }}
                    />
                    {label}
                  </Label>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </FormGroup>
      <FormGroup>
        <Label className="c-teachers-invite-modal__text pb-2">
          SHARE {gettextObj.gettext("CLASSES")} OR{" "}
          {gettextObj.gettext("GRADES")}
        </Label>
        <div className="py-3">
          <ButtonGroup>
            <Button
              className="btn-mid"
              active={shareMode === 1}
              onClick={() => {
                setAssignmentsToSelect(defaultAssignments)
                setShareType(GRADES)
                setShareMode(1)
              }}>
              Share {gettextObj.gettext("Grade")}
            </Button>
            <Button
              className="btn-mid"
              active={shareMode === 2}
              onClick={() => {
                populateAllGrades()
                setShareType(GRADES)
                setShareMode(2)
              }}>
              Share All {gettextObj.gettext("Grades")}
            </Button>
            <Button
              className="btn-mid"
              active={shareMode === 3}
              disabled={isWritableSharedSolution}
              onClick={() => {
                setAssignmentsToSelect(defaultAssignments)
                setShareType(CLASSES)
                setShareMode(3)
              }}>
              Share {gettextObj.gettext("Class")}
            </Button>
            <Button
              className="btn-mid"
              active={shareMode === 4}
              disabled={isWritableSharedSolution}
              onClick={() => {
                populateAllClasses()
                setShareType(CLASSES)
                setShareMode(4)
              }}>
              Share All {gettextObj.gettext("Classes")}
            </Button>
          </ButtonGroup>
        </div>

        <ShareSolutionByGradesOrClasses
          assignments={
            shareMode === 1 || shareMode === 3 ? assignmentsToSelect : []
          }
          assignmentsToSelect={assignmentsToSelect}
          setAssignmentsToSelect={setAssignmentsToSelect}
          teacherWithSharedSolutionToEdit={teacherWithSharedSolutionToEdit}
          availableGradesOrClasses={
            shareType === GRADES ? availableGrades : availableClasses
          }
          availableTeachers={availableTeachers}
          onAssignmentsChange={onAssignmentsChange}
          shareType={shareType}
        />
      </FormGroup>
      <div className="d-flex flex-column align-items-end mt-3">
        <ButtonBar
          disabled={shareDisabled}
          cancelText="Close"
          onCancelClick={toggle}
          onButtonClick={onShareClick(shareType)}
          buttonText="Share Lists"
        />
        {teachersMissingEmail && (
          <InlineError text="One or more teachers do not have a real email" />
        )}
        {shareErrorMessage && <InlineError text={shareErrorMessage} />}
        {unshareErrorMessage && <InlineError text={unshareErrorMessage} />}
      </div>
    </>
  )
}
